import React, { useState, useEffect } from 'react'
import { Button, DatePicker, message, Select } from 'antd'
import moment from 'moment'
import 'antd/dist/antd.css'
import './App.css'
import axios from 'axios'
const options = [

  {
    value: "AL0",
    label: "Aluminum"
  },
  {
    value: "CU0",
    label: "Copper"
  },
  {
    value: "HC0",
    label: "Hot rolled steel"
  },
  {
    value: "NI0",
    label: "Nickel"
  },
  {
    value: "RB0",
    label: "Spiral steel"
  },
  {
    value: "WR0",
    label: "Wire steel"
  },
  {
    value: "ZN0",
    label: "Zinc"
  },

]
export default function App() {
  const [data, setData] = useState([])
  const [title, setTitle] = useState('加载中...')
  const [goods, setGoods] = useState('AL0')
  const [date, setDate] = useState('')
  document.title = title
  const loadData = (type) => {
    axios.get('/futures/api/json.php/IndexService.getInnerFuturesDailyKLine', {
      params: {
        symbol: type
      }
    }).then(res => {
      if (date) {
        setData(res.data.filter(i => moment(i[0]).format('DD.MM.YYYY') === date))
      } else {
        setData(res.data.reverse())
      }
      switch (type) {
        case 'AL0':
          setTitle('Aluminum')
          break;
        case 'CU0':
          setTitle('Copper')
          break;
        case 'HC0':
          setTitle('Hot rolled steel')
          break;
        case 'NI0':
          setTitle('Nickel')
          break;
        case 'RB0':
          setTitle('Spiral steel')
          break;
        case 'WR0':
          setTitle('Wire steel')
          break;
        case 'ZN0':
          setTitle('Zinc')
          break;
        default:
          break;
      }
      message.success(`loaded ${type}`)
    }).catch(error => {
      message.error(error.response.data.message)
    })
  }
  useEffect(() => {
    loadData('AL0')
  }, [])
  const filterData = () => {
    loadData(goods)
  }
  const dateChange = (_, dateString) => {
    setDate(dateString)
  }
  return (
    <div style={{ width: "100%" }}>
      <header>
        <div className='search_area'>
          <div>
            <DatePicker
              format={'DD.MM.YYYY'}
              style={{ margin: '0 10px 0 10px' }}
              onChange={dateChange}
            />
            <Select
              style={{ width: 180, borderRadius: '6px' }}
              options={options}
              defaultValue='AL0'
              onChange={(e) => { setGoods(e) }}
            />
            <Button
              type='primary'
              style={{ margin: '0 20px 0 10px', borderRadius: '6px', background: '#1677ff', borderColor: '#1677ff' }}
              onClick={filterData}
            >Search</Button>
          </div>
          <span className='unify_font'>Data&nbsp;source:&nbsp;finance.sina.com.cn</span>
        </div>
        <div className='title'>
          <div>Date</div>
          <div>Close</div>
          <div>Open</div>
          <div>Highest</div>
          <div>Lowest</div>
        </div>
      </header>
      <div style={{ height: '50px', width: '100%' }}></div>
      <div style={{ height: '50px', width: '100%' }}></div>
      {
        data && data.map((i) => (
          <div className='item'>
            <div>{moment(i[0]).format('DD.MM.YYYY')}</div>
            <div>{i[4].slice(-i[4].length, -4)}</div>
            <div>{i[1].slice(-i[1].length, -4)}</div>
            <div>{i[2].slice(-i[2].length, -4)}</div>
            <div>{i[3].slice(-i[3].length, -4)}</div>
          </div>
        ))
      }
    </div>
  )
}
